














import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerRolesModule from '@/store/modules/manager/roles'
import { RoleResource } from '@/store/types'

@Component({
  components: {
    LinkTabs,
  },
})
export default class RolesItem extends Mixins(NotifyMixin) {
  private get roleUUID () {
    return this.$route.params.roleUUID
  }

  private get breadcrumbs () {
    return [{ name: 'Группы прав доступа', path: '/manager/users/roles' }]
  }

  private get tabs () {
    return [
      {
        name: 'Настройка',
        to: {
          name: 'manager.users.roles.item.permissions',
          params: { roleUUID: this.role?.uuid },
        },
      },
      {
        name: 'Пользователи',
        to: {
          name: 'manager.users.roles.item.users',
          params: { roleUUID: this.role?.uuid },
        },
      },
    ]
  }

  private get role () {
    return ManagerRolesModule.role
  }

  private get isRoleRootRoute () {
    return this.$route.name === 'manager.users.roles.item'
  }

  private created () {
    ManagerRolesModule.fetchPermissions()
      .then(() => {
        if (!this.role || this.isRoleRootRoute) {
          this.fetchRole()
        }
      })
      .catch((error) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.users.roles' })
      })
  }

  private fetchRole () {
    ManagerRolesModule.fetchRole(this.roleUUID)
      .then((response: RoleResource) => {
        if (this.$route.name === 'manager.users.roles.item') {
          this.$router.replace({
            name: 'manager.users.roles.item.permissions',
            params: { roleUUID: response.uuid },
          })
        }
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.users.roles' })
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Группа прав "${this.role?.title}"`,
    }
  }
}
